import React, { Component } from 'react'

import voltaire from "./assets/images/tarifs/dessin-voltaire.jpg"
import bridge from "./assets/images/tarifs/dessin-bridge.jpg"
import crapaud from "./assets/images/tarifs/dessin-crapaud.jpg"
import artdeco from "./assets/images/tarifs/dessin-art-deco.jpg"

export default class Tarifs extends Component {
    render() {
        return (
            <div>
                <h2 className="text-custom title-galerie title-tarifs" >Tarifs réfection de sièges</h2>
                <p>A titre indicatif, voici quelques exemples de tarifs pour une simple couverture (enlèvement de l’ancien tissu, pose du nouveau tissu en finition galonnée ou cloutée) ou une réfection complète.<br />
                    Un devis sera établi après avoir vu le siège concerné.<br />
                    Ces tarifs s’entendent :<br />
                    – hors tissu et finition (clous ou passementeries)<br />
                    – hors travaux nécessaires sur les bois (tels que recollage, décapage, etc…)<br />
                    Lorsque le tissu est fourni par le client, ces tarifs font l’objet d’une majoration de 20%.</p>
                <p>
                    <a href="./CGV.pdf">Conditions Générales de Vente</a></p>
                <div className="mt-2 display-tarifs">
                    <div className="d-flex">
                        <div className="col-4">
                            <img alt="Bridge"  className="w-100 mt-4" src={bridge}></img>
                        </div>

                        <div className="col-8 p-3">
                            <h2 className="">Bridge</h2>
                            <ul className="p-0" style={{ listStyle: "none", listStylePosition: "inside" }}>
                                <li className="mb-1" style={{ lineHeight: "1.625" }}> Couverture 200-250€ </li>
                                <li className="mb-1" style={{ lineHeight: "1.625" }}> Réfection 350-400€ </li>
                            </ul>
                        </div>
                    </div>
                    <div className="d-flex">
                        <div className="col-4">
                            <img alt="Voltaire" className="w-100 mt-2" src={voltaire}></img>
                        </div>

                        <div className="col-8 p-3">
                            <h2 className="">Voltaire</h2>
                            <ul className="p-0" style={{ listStyle: "none", listStylePosition: "inside" }}>
                                <li className="mb-1" style={{ lineHeight: "1.625" }}> Couverture 300-400€ </li>
                                <li className="mb-1" style={{ lineHeight: "1.625" }}> Réfection 600-800€ </li>
                            </ul>
                        </div>
                    </div>
                    <div className="d-flex">
                        <div className="col-4">
                            <img alt="Artdeco" className="w-100 mt-4" src={artdeco}></img>
                        </div>

                        <div className="col-8 p-3">
                            <h2 className="">Fauteuil Art Déco</h2>
                            <ul className="p-0" style={{ listStyle: "none", listStylePosition: "inside" }}>
                                <li className="mb-1" style={{ lineHeight: "1.625" }}> Couverture 400-500€ </li>
                                <li className="mb-1" style={{ lineHeight: "1.625" }}> Réfection 700-800€ </li>
                            </ul>
                        </div>
                    </div>

                    <div className="d-flex">
                        <div className="col-4">
                            <img alt="Crapaud" className="w-100 mt-4" src={crapaud}></img>
                        </div>

                        <div className="col-8 p-3">
                            <h2 className="">Crapaud</h2>
                            <ul className="p-0" style={{ listStyle: "none", listStylePosition: "inside" }}>
                                <li className="mb-1" style={{ lineHeight: "1.625" }}> Couverture 500-600€ </li>
                                <li className="mb-1" style={{ lineHeight: "1.625" }}> Réfection 800-900€ </li>
                            </ul>
                        </div>
                    </div>
                    <div>

                    </div>
                </div>
            </div>
        )
    }
}
