import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faBars } from '@fortawesome/free-solid-svg-icons'

import logo from "./assets/GAGA-de-Déco-Main-Logo-BLACK-PNG-FOR-WEB-USE-ONLY.png"

import cadre from "./assets/icons/cadre.png"
import lampe from "./assets/icons/lampe.png"
import metre from "./assets/icons/metre.png"

import coussins from "./assets/icons/coussins.png"

import sieges from "./assets/icons/sieges.png"

import history from "./history.js";
import { useState } from 'react';

const PRESENTATION = ""
const SIEGES = "sieges"
const ABAT_JOUR = "abat-jour"
const TARIFS = "tarifs"
const COUSSINS = "coussins"

export default function Menu(props) {

    const [hiddenmenu, setHidenmenu] = useState(true);

    const changePage = (page, e) => {
        e.preventDefault()
        hideMenu()
        history.push("/" + page)
    }

    const hideMenu = () => {
        setHidenmenu(true)
    }

    const myFunction = (e) => {
        console.log(hiddenmenu)
        setHidenmenu(!hiddenmenu)
        e.preventDefault()
    }

    return (
        <div className="d-flex justify-content-center w-100 flex-column">
            <div className="justify-content-center w-100 mb-5 logo-display">
                <img alt="Main Logo" src={logo} className="main-logo" />
            </div>
            <div className="d-flex justify-content-center w-100 menu-container">
                <nav className="justify-content-between menu">
                    <a onClick={(e) => changePage(PRESENTATION, e)} href="/" className="nav-link menu-link justify-content-center d-flex flex-column" style={{ width: "16.66%" }}>
                        <div className="d-flex justify-content-center">
                            <img alt="Cadre" src={cadre} style={{ height: "64px" }} className="mb-4" />
                        </div>
                        <span className="text-center text-black">Qui suis-je ?</span>
                    </a>

                    <a onClick={(e) => changePage(SIEGES, e)} href="/sieges" className="nav-link menu-link justify-content-center d-flex flex-column" style={{ width: "16.66%" }}>
                        <div className="d-flex justify-content-center">
                            <img alt="Siège" src={sieges} style={{ height: "64px" }} className="mb-4" />
                        </div>
                        <span className="text-center text-black">Sièges</span>
                    </a>
                    <a onClick={(e) => changePage(ABAT_JOUR, e)} href="/abat-jour" className="nav-link menu-link justify-content-center d-flex flex-column" style={{ width: "16.66%" }}>
                        <div className="d-flex justify-content-center">
                            <img alt="Lampe" src={lampe} style={{ height: "64px" }} className="mb-4" />
                        </div>

                        <span className="text-center text-black">Abat Jour</span>
                    </a>


                    <a onClick={(e) => changePage(COUSSINS, e)} href="/coussins" className="nav-link menu-link justify-content-center d-flex flex-column" style={{ width: "16.66%" }}>
                        <div className="d-flex justify-content-center">
                            <img alt="Coussins" src={coussins} style={{ height: "64px" }} className="mb-4" />
                        </div>

                        <span className="text-center text-black">Coussins</span>
                    </a>
                    <a onClick={(e) => changePage(TARIFS, e)} href="/tarifs" className="nav-link menu-link justify-content-center d-flex flex-column" style={{ width: "16.66%" }}>
                        <div className="d-flex justify-content-center">
                            <img alt="Metre" src={metre} style={{ height: "64px" }} className="mb-4" />
                        </div>

                        <span className="text-center text-black">Tarifs</span>
                    </a>
                </nav>
            </div>
            <nav className='menu-mobile'>
                <div className="topnav">
                    <a onClick={(e) => changePage(PRESENTATION, e)} href="/" className="justify-content-center d-flex">
                        <img src={logo} alt="Logo" style={{height:"60px",width:"60px"}} className="main-logo" />
                    </a>

                    {
                        !hiddenmenu && <div >
                            <a onClick={(e) => changePage(PRESENTATION, e)} href="/" className="nav-link menu-link justify-content-center d-flex flex-column">
                                <span className="text-center text-black" style={{ fontSize: "1.5rem" }}>Qui suis-je ?</span>
                            </a>

                            <a onClick={(e) => changePage(SIEGES, e)} href="/sieges" className="nav-link menu-link justify-content-center d-flex flex-column">
                                <span className="text-center text-black" style={{ fontSize: "1.5rem" }}>Sièges</span>
                            </a>
                            <a onClick={(e) => changePage(ABAT_JOUR, e)} href="/abat-jour" className="nav-link menu-link justify-content-center d-flex flex-column">

                                <span className="text-center text-black" style={{ fontSize: "1.5rem" }}>Abat Jour</span>
                            </a>


                            <a onClick={(e) => changePage(COUSSINS, e)} href="/coussins" className="nav-link menu-link justify-content-center d-flex flex-column">

                                <span className="text-center text-black" style={{ fontSize: "1.5rem" }}>Coussins</span>
                            </a>

                            <a onClick={(e) => changePage(TARIFS, e)} href="/tarifs" className="nav-link menu-link justify-content-center d-flex flex-column">

                                <span className="text-center text-black" style={{ fontSize: "1.5rem" }}>Tarifs</span>
                            </a>
                        </div>
                    }

                    <a href="" className="icon p-4" onClick={(e) => myFunction(e)}>
                        <FontAwesomeIcon icon={faBars} />
                    </a>
                </div>
            </nav>
        </div>
    )

}
