import React from 'react'
import {
    Router,
    Switch,
    Route,
} from "react-router-dom";

import history from "./history.js";

import GaleriePhoto from './GaleriePhoto';
import Presentation from "./Presentation"
import Tarifs from './Tarifs.js';

import lalilogo from "./assets/images/logos/LOGO-LD-VECT-SITE-2.png"
import prestilogo from "./assets/images/logos/PrestigiousTextiles_logo.png"
import thevenonlogo from "./assets/images/logos/Thevenon-1024x418.png"
import houleslogo from "./assets/images/logos/houleslogo.png"
import casamancelogo from "./assets/images/logos/logo-casamance-nano.jpg"
import Contact from './Contact.js';





export default function Pages() {

    return (
        <Router history={history}>
            <Switch>
                <Route path="/sieges">
                    <GaleriePhoto type="sieges" />
                    <div className="d-flex justify-content-center mt-5" style={{ flexWrap: "wrap" }}>
                        <img alt="Logo Lali" style={{ height:"100px"}} className="m-2" src={lalilogo} />
                        <img alt="Logo Houles" style={{ height: "100px"}} className="m-2" src={houleslogo} />

                        <img alt="Logo The Venon" style={{ height: "100px"}} className="m-2" src={thevenonlogo} />
                        <img alt="Logo Casamance" style={{ height: "90px"}} className="m-2" src={casamancelogo} />

                        <img alt="Logo Presti" style={{ height: "100px"}} className="m-2" src={prestilogo} />
                    </div>
                </Route>
                <Route path="/abat-jour">
                    <GaleriePhoto type="abat-jour" />
                </Route>
                <Route path="/rideaux">
                    <GaleriePhoto type="rideaux" />
                </Route>
                <Route path="/coussins">
                    <GaleriePhoto type="coussins" />
                    <div className="d-flex justify-content-center w-100 mt-5">
                        <img alt="Logo Lali" style={{ height:"100px" }} src={lalilogo} />
                    </div>
                </Route>
                <Route path="/tarifs">
                    <Tarifs />
                </Route>
                <Route path="/contact">
                    <Contact />
                </Route>
                <Route exact path="/">
                    <Presentation />
                </Route>
            </Switch>
        </Router>
    )
}
