import Footer from "./Footer";
import Menu from "./Menu"

import Router from './Router'

function App()  {


    
        return (
            <div className="main-padding" >
                <Menu />
                <div className="d-flex justify-content-center">
                    <div className="content-padding mt-4">
                        <Router />
                    </div>
                </div>
                <Footer />
            </div>
        );
    

}

export default App;
