import React, { Component } from 'react'

import image from "./assets/images/photo-presentation.jpg"
import logoartisanart from "./assets/images/artisan-art-logo.png"

export default class Presentation extends Component {
    render() {
        return (
            <div className="w-full max-w-5xl relative">
                <div className="grid gap-10">
                    <div className='w-100 d-flex justify-content-center'>
                        <img alt="Moi" src={image} className="mb-5 w-75" />
                    </div>

                    <div>
                        <p className="text-justify"> Artisan d’art tapissier décorateur<br /><br />

                            GAGA de déco, c’est tout moi, Gaëlle GARRIGUES !!!<br /><br />

                            Au début, je commence par redécorer les chambres d’hôtes dans ma maison de maître dans le Gers (<a href="https://maisondoat1823.business.site/" style={{ textDecoration: "none" }}>Maison Doat 1823</a>), la récupération et le détournement d’objets sont le mot d’ordre.<br />

                            Puis une reconversion professionnelle s’impose, et je décide d’approfondir les choses en passant mon CAP de tapisserie d’ameublement en siège dans les Landes.<br />

                            Aujourd’hui je vous propose de remettre au goût du jour vos fauteuils de grand-mère oubliés au grenier, de redonner vie aux sièges défraîchis chinés dans les brocantes.
                            Pour compléter, je pourrai vous guider dans votre décoration d’intérieur avec la confection de rideaux, création de coussins et abat jour sur-mesure.</p>
                        <div className="d-flex justify-content-center w-100"><img alt="Logo Artisan Art" className='img-presentation' src={logoartisanart} /></div>
                    </div>
                </div>
            </div>
        )
    }
}
