import React, { Component } from 'react'

import titleLoader from './titleLoader';
import imagesLoader from "./imagesLoader"

export default class Galerie extends Component {

    constructor(props) {
        super(props);
        this.state = {
            images: [],
        }
    }

    componentDidMount() {
        this.initImages();
    }

    componentDidUpdate(prevProps) {
        if (this.props.type !== prevProps.type) {
            this.initImages();
        }
    }



    initImages = () => {
        const { type } = this.props;

        function importAll(r) {
            return r.keys().map(r);
        }
        var images = importAll(imagesLoader[type]);
        images = images.map(image => {
            image = {src:image}
            //console.log(image)
            //console.log()
            image.rank = 999
            //image.default.split(".")[0].split("/")[3]
            titleLoader[type].forEach((elem,i)=> {
                //console.log(image)
                if(image.src && elem.fileName.toLowerCase()===image.src.split(".")[0].split("/")[3].toLowerCase()){
                    image.rank = i
                    image.title = elem.title
                }
            })
            return image
        })
        //console.log(images)
        images.sort((a,b) => {
            //console.log(a.rank, b.rank)
            return a.rank>b.rank || -(a.rank < b.rank)
        })
        this.setState({ images })

    }

    render() {
        const { images } = this.state;
        return (
            <div>
                {/*<h2 className="text-custom title-galerie" >Nouveautés</h2>*/}
                <div className="display-galerie">
                    {
                        images.map(image => {

                            return <div key={image.src} className="galerie-case mt-4">
                                <img  alt={image.title} src={image.src} className="img-galerie" />
                                {<label className="text-center img-galerie-text w-100" style={{ zIndex: -1, position: "relative", top: "-30px" }}>{image.title}</label>}
                            </div>

                        })
                    }
                </div>
            </div>
        )
    }
}
