import sieges from "./assets/images/sieges/title.json"
import abatjour from "./assets/images/abat-jour/title.json"
import coussins from "./assets/images/coussins/title.json"

const titleLoader = {
    "sieges": sieges,
    "abat-jour": abatjour,
    "coussins":coussins,
}

export default titleLoader;