import React, { Component } from 'react'

import axios from 'axios';

export default class Contact extends Component {
    constructor(props) {
        super(props);
        // N’appelez pas `this.setState()` ici !
        this.state = {
            nom: "",
            email: "",
            tel: "",
            subject: "",
            message: "",
        };
    }

    setNom = (value) => {
        this.setState({ confirmed:false,nom: value })
    }

    setEmail = (value) => {
        this.setState({ confirmed:false,email: value })
    }
    setTel = (value) => {
        this.setState({ confirmed:false,tel: value })
    }

    setSubject = (value) => {
        this.setState({ confirmed:false,subject: value })
    }

    setMessage = (value) => {
        this.setState({ confirmed:false,message: value })
    }
    

    sendMail = () => {

        

        const { nom, email, tel, subject, message } = this.state

        if (!nom || !email || !tel || !subject || !message) {
            this.setState({ erreur: "Veuillez remplire tous les champs" })
            return
        }

        if(!email.includes("@")){
            this.setState({ erreur: "Veuillez saisir une email valide" })
            return
        }

        if(!tel.replaceAll(" ","").match(/^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/)){
            this.setState({ erreur: "Veuillez saisir un numéro de téléphone valide" })
            return
        }

        this.setState({ erreur: "" })
        const body = { nom, email, tel, subject, message }
        axios.post("/mail", body).then(this.setState({confirmed:true,nom:"", email:"", tel:"", subject:"", message:""}))


    }

    render() {
        const { nom, email, tel, subject, message, erreur,confirmed } = this.state
        return (
            <div className="w-full max-w-5xl relative d-flex mobile-contact">
                <div className='contact-container'>
                    <h2 className="text-custom title-galerie" >Contact et Accès</h2>
                    <p>
                        <strong>Téléphone</strong> : <a href="tel:0670856339" style={{ textDecoration: "none" }}>06 70 85 63 39</a><br />
                        <strong>Email</strong> :  <a href="mailto:gagadedeco32@gmail.com?subject=Contact gagadedeco.fr" style={{ textDecoration: "none" }}> gagadedeco32@gmail.com</a>
                    </p>
                    <p>
                        <strong>Atelier et showroom </strong> <br />
                        24 Route des platanes<br />
                        32460 Perchède<br />
                        <br />
                        Accueil sur rendez-vous <strong>uniquement</strong> ou déplacement à domicile.<br />
                        <iframe className="mt-3"
                            title="map"
                            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11523.59745754898!2d-0.1336429!3d43.7749478!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x645629c658b16ebc!2sGAGA%20de%20D%C3%A9co!5e0!3m2!1sfr!2sfr!4v1646429832899!5m2!1sfr!2sfr"
                            height="450"
                            style={{ border: 0, width: "95%" }}
                            allowfullscreen=""
                            loading="lazy">
                        </iframe>
                    </p>
                </div>
                <div className='contact-container'>
                    <h2 className="text-custom title-galerie" >Envoyer un message</h2>

                    <div className="form-group">
                        <label for="exampleInputEmail1">Nom</label>
                        <input onChange={e => this.setNom(e.target.value)} type="text" className="form-control" id="exampleInputEmail1" placeholder="" value={nom} />
                    </div>
                    <div className="form-group mt-3">
                        <label for="exampleInputEmail1">Email</label>
                        <input onChange={e => this.setEmail(e.target.value)} type="email" className="form-control" id="exampleInputEmail1" placeholder="" value={email} />
                    </div>
                    <div className="form-group mt-3">
                        <label for="exampleInputEmail1">Numéro de téléphone</label>
                        <input onChange={e => this.setTel(e.target.value)} type="tel" className="form-control" id="exampleInputEmail1" placeholder="" value={tel} />
                    </div>
                    <div className="form-group mt-3">
                        <label for="exampleInputEmail1">Sujet</label>
                        <input onChange={e => this.setSubject(e.target.value)} type="text" className="form-control" id="exampleInputEmail1" placeholder="" value={subject} />
                    </div>

                    <div className="form-group mt-3">
                        <label for="exampleFormControlTextarea1">Message</label>
                        <textarea onChange={e => this.setMessage(e.target.value)} className="form-control" id="exampleFormControlTextarea1" rows="3" value={message}></textarea>
                    </div>
                    <button onClick={this.sendMail} className="btn btn-secondary mt-3">Envoyer</button>
                    {erreur!="" && <div className='text-danger'>{erreur}</div>}
                    {confirmed && <div className='text-success'>Votre message a bien été envoyé</div>}
                </div>

            </div>
        )
    }
}
